<template>
  <div>
    <van-overlay :show="isLoading" zIndex="2">
      <van-loading color="#1989fa" size="64" class="txt-c" />
    </van-overlay>

    <van-nav-bar :title="$t('pageTitle.task_category')" :border="false">
      <template #left>
        <div class="page-title-btn" @click="$router.go(-1)">
          <img src="@/assets/images/arrow_left.svg" alt="" srcset="" />
        </div>
      </template>
    </van-nav-bar>

    <div class="categoryes">
      <div
        class="categoryes-item"
        v-for="(item, key) in categoryList"
        :key="key"
      >
        <p class="categoryes-item__text">{{ item.title }}</p>

        <van-button
          plain
          type="primary"
          class="m-l"
          @click="deleteItem(item)"
          v-if="item.id != 0"
        >
          {{ $t("btn.delete") }}
        </van-button>
      </div>
    </div>

    <van-button plain type="primary" class="m-a" @click="isCreate = true">
      <img src="@/assets/images/plus.svg" class="icon" />
      {{ $t("btn.add") }}
    </van-button>
    <CatagoryCreate :isCreate="isCreate" @close="getData()" />
  </div>
</template>

<script>
import ApiCategory from "@/api/category";
import CatagoryCreate from "@/components/Category/Create";
export default {
  data() {
    return {
      isCreate: false,
      isError: false,
      isLoading: true,
      form: {},
      categoryList: [],
    };
  },
  components: {
    CatagoryCreate,
  },
  methods: {
    deleteItem(item) {
      this.$dialog
        .confirm({
          cancelButtonText: this.$t("category.no"),
          confirmButtonText: this.$t("category.yes"),
          closeOnClickOverlay: true,
          message: this.$t("category.confirm_delete"),
        })
        .then(() => {
          ApiCategory.deleteCategory(item.id).then(() => {
            this.getData();
          });
        });
    },
    getData() {
      this.isLoading = true;
      this.isCreate = false;
      ApiCategory.getCategoryList().then((responce) => {
        if (responce.data.length) {
          this.categoryList = responce.data;
        }
        this.isLoading = false;
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.categoryes {
  background: #f9f8f9;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  &-item {
    padding: 8px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    &__text {
    }
    &__delete {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
